import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';

// ================ Action types ================ //

export const FETCH_LISTINGS_AND_STORES_REQUEST = 'app/LikesPage/FETCH_LISTINGS_AND_STORES_REQUEST';
export const FETCH_LISTINGS_AND_STORES_SUCCESS = 'app/LikesPage/FETCH_LISTINGS_AND_STORES_SUCCESS';
export const FETCH_LISTINGS_AND_STORES_ERROR = 'app/LikesPage/FETCH_LISTINGS_AND_STORES_ERROR';

// ================ Reducer ================ //

const initialState = {
    fetchInProgress: false,
    fetchListingsAndStoresError: null,
    favoriteListings: [],
    favoriteStores: [],
};

export default function likesPageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_LISTINGS_AND_STORES_REQUEST:
            return { ...state, fetchInProgress: true, fetchListingsAndStoresError: null };

        case FETCH_LISTINGS_AND_STORES_SUCCESS:
            return {
                ...state,
                fetchInProgress: false,
                favoriteListings: payload.favoriteListings,
                favoriteStores: payload.favoriteStores,
            };

        case FETCH_LISTINGS_AND_STORES_ERROR:
            console.error(payload); // eslint-disable-line
            return { ...state, fetchInProgress: false, fetchListingsAndStoresError: payload };

        default:
            return state;
    }
}

// ================ Action creators ================ //

const fetchListingsAndStoresRequest = () => ({ type: FETCH_LISTINGS_AND_STORES_REQUEST });
const fetchListingsAndStoresSuccess = (favoriteListings, favoriteStores) => ({
    type: FETCH_LISTINGS_AND_STORES_SUCCESS,
    payload: { favoriteListings, favoriteStores },
});
const fetchListingsAndStoresError = e => ({
    type: FETCH_LISTINGS_AND_STORES_ERROR,
    error: true,
    payload: e,
});

// ================ Thunks ================ //

export const loadData = () => async (dispatch, getState, sdk) => {
    try {
        dispatch(fetchListingsAndStoresRequest());
        let currentUser

        currentUser  = getState().user.currentUser;
        
        if (!currentUser) {
           const res = await sdk.currentUser.show()
           currentUser = res.data.data
        }
        const favoriteKeys = currentUser?.attributes?.profile?.privateData?.favorites || [];
        const favoriteStores = currentUser?.attributes?.profile?.privateData?.favoriteStores || [];

        const listingQueryParams = {
            ids: favoriteKeys,
            include: ['images', 'author'],
            'fields.image': [
                'variants.scaled-small',
                'variants.scaled-medium',
                `variants.listing-card`,
                `variants.listing-card-2x`,
            ],
            ...createImageVariantConfig('listing-card', 400, 1),
            ...createImageVariantConfig('listing-card-2x', 800, 1),
        };

        const listingPromise = sdk.listings.query(listingQueryParams);

        const favoriteStoresWithReviewsResponse = await Promise.all(
            favoriteStores.map(async storeId => {
                const [storeResponse, reviewsResponse] = await Promise.all([
                    sdk.users.show({
                        id: storeId,
                        include: ['profileImage'],
                        'fields.image': [
                            'variants.scaled-small',
                            'variants.scaled-medium',
                            `variants.listing-card`,
                            `variants.listing-card-2x`,
                        ],
                        ...createImageVariantConfig('listing-card', 400, 1),
                        ...createImageVariantConfig('listing-card-2x', 800, 1),
                    }),
                    sdk.reviews.query({
                        subject_id: storeId,
                        state: 'public',
                    }),
                ]);

                return {
                    ...storeResponse,
                    data: {
                        ...storeResponse?.data,
                        data: {
                            ...storeResponse?.data?.data,
                            attributes: {
                                ...storeResponse.data?.data?.attributes,
                                reviews: reviewsResponse?.data?.data,
                            }
                        },
                    },
                };
            })
        );

        const favoriteListingsResponse = await listingPromise;
        const favoriteListings = favoriteListingsResponse?.data?.data;
        const favoriteStoresAndReviews = favoriteStoresWithReviewsResponse.map(response => response.data.data);

        dispatch(addMarketplaceEntities(favoriteListingsResponse));
        favoriteStoresWithReviewsResponse.forEach(storeWithReviews =>
            dispatch(addMarketplaceEntities(storeWithReviews))
        );

        dispatch(fetchListingsAndStoresSuccess(favoriteListings, favoriteStoresAndReviews));
    } catch (e) {
        dispatch(fetchListingsAndStoresError(storableError(e)));
        throw e;
    }
};
