import React from 'react';
import classNames from 'classnames';
import styles from './LocagoButton.module.css';

const LocagoButton = props => {
  const { isInverted = false, isBig = false, className = null, children, disabled } = props;

  const buttonClass = classNames(styles.LocagoButton, {
      [styles['LocagoButton--inverted']]: isInverted,
    },
    {
      [styles['LocagoButton--big']]: isBig,
    },
    className,
  );

  return (
    <button disabled={disabled} className={buttonClass}>
      {children}
    </button>
  );
};

export default LocagoButton;
