import React, { useState, useCallback, useEffect } from 'react';
import styles from './Variants.module.css';
import { sortSize } from '../../util/sortSize';


const getColorStyle = (color) => {
  const isHex = (value) => /^#([0-9A-F]{3}){1,2}$/i.test(value);
  const isRGB = (value) => /^rgb\(\d{1,3},\s?\d{1,3},\s?\d{1,3}\)$/i.test(value);
  const isRGBA = (value) => /^rgba\(\d{1,3},\s?\d{1,3},\s?\d{1,3},\s?(0|0?\.\d+|1)\)$/i.test(value);

  let colorValue = color?.value?.trim();
  if (colorValue && !colorValue.startsWith('#') && colorValue.length === 6 && isHex(`#${colorValue}`)) {
    colorValue = `#${colorValue}`;
  }

  if (!isHex(colorValue) && !isRGB(colorValue) && !isRGBA(colorValue)) {
    colorValue = '#000';
  }

  return { backgroundColor: colorValue };
};

const getBorderStyle = (colorValue) => (
  ['#fff', '#ffffff', 'white'].includes(colorValue) ? { borderColor: '#E4E4E4', width: '35px', height: '35px' } : {}
);

const getDefaultVariants = (variants) => {
  const variantOptions = variants?.reduce((acc, option) => {
    const type = (option?.optionType || option?.option_type || '').toLowerCase();

    // Handle inconsistent data when option type comes as 'drop_down' for size
    let updatedType = ['dropdown', 'drop_down'].includes(type) ? 'size' : type;

    if (!acc[updatedType]) acc[updatedType] = [];
    acc[updatedType].push(
      ...((option?.choices || [])
        .filter(choice => choice?.visible)
        .map(choice => {
          return ({ ...choice, available: true });
        })));
    return acc;
  }, {});
  return variantOptions || [];
}

const getMappedVariants = (variants, availableChoices, selectedVariant = null) => {

  const selectedVariantValues = Object.values(selectedVariant);
  if (selectedVariantValues.every(value => (!value || value === ""))) {
    return getDefaultVariants(variants);
  }

  const mappedVariants = {};

  variants?.forEach(option => {
    const type = (option?.optionType || option?.option_type || '').toLowerCase();
    let updatedType = ['dropdown', 'drop_down'].includes(type) ? 'size' : type;

    if (!mappedVariants[updatedType]) {
      mappedVariants[updatedType] = [];
    }

    option?.choices?.forEach(choice => {
      if (choice.visible) {
        mappedVariants?.[updatedType].push({ ...choice, available: true });
      }
    });
  });

  const selectedKeys = Object.keys(selectedVariant);

  selectedKeys?.forEach(selectedKey => {
    const selectedValue = selectedVariant[selectedKey]?.description;
    if (selectedValue) {
      const availableCombinations = new Set(
        availableChoices
          .filter(choice => choice[selectedKey.charAt(0).toUpperCase() + selectedKey.slice(1)] === selectedValue)
          .flatMap(choice => Object.keys(choice).filter(key => key !== selectedKey).map(key => choice[key]))
      );

      Object.keys(mappedVariants).forEach(variantType => {
        if (variantType !== selectedKey) {
          mappedVariants[variantType].forEach(variantChoice => {
            variantChoice.available = availableCombinations.has(variantChoice?.description);
          });
        }
      });
    }
  });

  return mappedVariants;
};

const Variants = ({ variants = [], selectedVariant = {}, variantHandler, availableChoices = [], childListings = [] }) => {

  const [selectedValues, setSelectedValues] = useState({});
  const [productVariants, setProductVariants] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(false)

  const changeHandler = useCallback((key, value) => {
    variantHandler(prevState => ({
      ...prevState,
      [key]: value,
    }));

    setSelectedValues(prev => ({
      ...prev,
      [key]: value.description,
    }));
  }, [variantHandler]);

  const handleSelect = useCallback((key, choice) => {
    if (!choice.inStock || !choice.available) return;
    setSelectedValues(prev => {
      const newValue = prev[key] === choice.description ? '' : choice.description;
      const newVariantState = newValue ? choice : '';

      variantHandler(prevState => ({
        ...prevState,
        [key]: newVariantState,
      }));

      return {
        ...prev,
        [key]: newValue,
      };
    });
  }, [changeHandler, variantHandler]);

  useEffect(() => {
    if (variants?.length > 0) {
      setProductVariants(getMappedVariants(variants, availableChoices, selectedVariant))
    }
  }, [variants, selectedVariant, availableChoices]);

  useEffect(() => {
    // functionality for automatically select first variant
    if (childListings.length > 0 && productVariants && !isFirstRender) {
      const firstChildListing = childListings[0]?.attributes.publicData.choices || [];

      const filteredVariants = {};

      Object.keys(firstChildListing).forEach((variantType) => {
        const selectedValue = firstChildListing[variantType];

        // Find the corresponding variant group in productVariants (e.g., size, color, etc.)
        const variantGroup = productVariants[variantType.toLowerCase()]; // Ensure we match keys in lowercase (size, color)

        if (variantGroup) {
          filteredVariants[variantType.toLowerCase()] = variantGroup.filter(
            (option) => option.description === selectedValue || option.value === selectedValue
          );
        }
      });


      Object.keys(filteredVariants).map((filtered) => {
        handleSelect(filtered, filteredVariants[filtered][0])
      })

      setIsFirstRender(true)
    }
  }, [productVariants, childListings])

  const productVariantsSorted = productVariants.size ? { ...productVariants, size: sortSize(productVariants.size) } : productVariants;

  if (!childListings.length || !productVariants) {
    return null
  }

  return (
    <>
      {Object.keys(productVariants || {})?.length > 0 ? (
        Object.keys(productVariants || {})?.sort().map((variantType) => {

          const variantTypeLabel = variantType?.charAt(0).toUpperCase() + variantType?.slice(1);
          const selectedVariantValue = selectedValues?.[variantType];

          return (
            <div key={variantType} className={styles.optionGroup}>

              <span className={styles.label}>
                {
                  selectedVariantValue
                    ? <>
                      {`${variantTypeLabel}:`} <span className={styles.colorName}>{selectedVariantValue}</span>
                    </>
                    : null
                }
              </span>

              <div className={variantType === 'color' ? styles.colors : styles.sizes}>
                {productVariantsSorted[variantType]?.filter(product => product.inStock).map(choice => (
                  variantType === 'color' ? (
                    <div
                      key={choice.description}
                      className={`
                        ${styles.colorBorderOption} 
                        ${selectedVariant[variantType]?.value === choice.value ? styles.selected : ''}
                        ${!choice?.available ? `${styles.notAvailable} ${styles.notAvailableColor}` : ''}
                        `}
                      style={getBorderStyle(choice.value)}
                    >
                      <div
                        className={`${styles.colorOption} ${!choice.inStock ? styles.outOfStock : ''}`}
                        style={getColorStyle(choice)}
                        onClick={() => handleSelect(variantType, choice)}
                      />
                    </div>
                  ) : (
                    <button
                      type="button"
                      key={choice?.value}
                      className={`
                        ${styles.sizeOption} 
                        ${selectedVariant[variantType]?.value === choice.value ? styles.selected : ''}
                        ${!choice?.available ? styles.notAvailable : ''}
                        `}
                      onClick={() => handleSelect(variantType, choice)}
                      disabled={!choice?.inStock}
                    >
                      <div className={styles.borderMain}> </div>
                      {choice?.description}
                    </button>
                  )
                ))}
              </div>
            </div>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default Variants;
