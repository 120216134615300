import React, { useState } from 'react';
import css from './Footer.module.css';
import { injectIntl } from 'react-intl';
import { withViewport } from '../../../util/uiHelpers';
import { compose } from 'redux';
import { connect } from 'react-redux';

const FooterSectionComp = (props) => {
    const { isAuthenticated } = props;
    const [email, setEmail] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (email) {
            const form = document.getElementById('mc-embedded-subscribe-form');
            if (form) {
                form.submit(); // Submit to Mailchimp
                setEmail('')
            }
        }
    };

    return (
        <div className={css.footer}>
            <div className={css.footerContainer}>
                <div className={css.footerContent}>
                    <h2 className={css.newsLetter}>SIGN UP FOR LOCAGO NEWS</h2>
                    <div >
                        <form
                            action="https://shop.us14.list-manage.com/subscribe/post?u=f16f7693a9faf45c3f0367b33&amp;id=5597c2868c&amp;f_id=00c237e1f0" method="post"
                            id="mc-embedded-subscribe-form"
                            name="mc-embedded-subscribe-form"
                            target="_blank"
                            onSubmit={handleSubmit}
                            className={css.inputGroup}
                        >

                            <input
                                type="email"
                                name="EMAIL"
                                id="mce-EMAIL"
                                className="required email"
                                value={email}
                                onChange={handleEmailChange}
                                required
                                placeholder="Your email"
                            />
                            <button
                                type="submit"
                                id="mc-embedded-subscribe"
                                className={css.subscribeButton}
                                disabled={!email}
                            >
                                SUBSCRIBE
                            </button>
                        </form>
                    </div>
                    <div className={css.socialIcons}>
                        <a href="https://www.tiktok.com/@shop_locago" target="_blank" rel="noopener noreferrer">
                            <img src={require('../icons/tiktok.png')} alt="tiktok icon" />
                        </a>
                        <a href="https://www.instagram.com/shoplocago/?hl=en" target="_blank" rel="noopener noreferrer">
                            <img src={require('../icons/instagram.png')} alt="instagram icon" />
                        </a>
                        <a href="https://www.facebook.com/people/Locago/61558000775042/" target="_blank" rel="noopener noreferrer">
                            <img src={require('../icons/facebook.png')} alt="facebook icon" />
                        </a>
                    </div>
                </div>
                <div className={css.links}>
                    <a href="/FAQs">FAQS</a>
                    <a href="/terms">TERMS & CONDITIONS</a>
                    <a href="mailto:info@locago.shop">CONTACT</a>
                {!isAuthenticated &&  <a href="/signup/store_owner">REGISTER YOUR BUSINESS</a>}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    const { isAuthenticated } = state.auth;

    return {
      isAuthenticated
    };
  };

const FooterSection = compose(
    connect(
      mapStateToProps    ),
    withViewport,
    injectIntl
  )(FooterSectionComp);
export default FooterSection;
