import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';

import backarrow from './SliderImages/backarrow.svg';
import nextarrow from './SliderImages/nextarrow.svg';
import css from './OrderPanel.module.css';
import NamedLink from '../NamedLink/NamedLink';
import { createSlug } from '../../util/urlHelpers';
import useWindowSize from '../../hooks/useWindowSize';

if (typeof window !== 'undefined') {
    import('pure-react-carousel/dist/react-carousel.es.css');
}

const RecommendationItems = (props) => {

    const { recommendedListings = [] } = props;
    const { width } = useWindowSize();

    if (recommendedListings.length === 0) {
        return null;
    }

    let visibleSlides = 1;
    if (width < 530) {
        visibleSlides = 2;
    } else {
        if (width < 768) {
            visibleSlides = 2;
        } else {
            visibleSlides = 3
        }
    }

    return (
        <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={100}
            totalSlides={recommendedListings.length}
            visibleSlides={visibleSlides}
            step={1}
            infinite={true}
            className={css.recommendedItems}
        >
            <ButtonBack className={css.leftArrowBtn}>
                <img src={backarrow} alt={'back arrow'} style={{ width: '40px', height: '40px' }} />
            </ButtonBack>
            <Slider className={css.recommendedSlider}>
                {recommendedListings.map((l, index) => {
                    const slug = createSlug(l?.attributes?.title);
                    const listingImageURL = l?.images?.[0]?.attributes?.variants?.['listing-card']?.url || '';
                    return (
                        <Slide index={index} key={l?.id?.uuid || index} className={css.recommendedItemsMain}>
                            <NamedLink name="ListingPage" params={{ id: l?.id?.uuid, slug }}>
                                <div className={css.recommendedItemsImage}>
                                    <img src={listingImageURL} alt="Product Image" />
                                </div>
                                <div className={css.recommendedItemsData}>
                                    <h4 className={css.headingTruncate}>
                                        {l?.author?.attributes?.profile?.displayName || 'Unknown Author'}
                                    </h4>
                                    <p>{l?.attributes?.title || 'No Title'}</p>
                                    <div className={css.price}>
                                        {l?.attributes?.price ? `$${l.attributes.price.amount}` : 'Price Unavailable'}
                                    </div>
                                </div>
                            </NamedLink>
                        </Slide>
                    );
                })}
            </Slider>
            <ButtonNext className={css.rightArrowBtn}>
                <img src={nextarrow} alt={'next arrow'} style={{ width: '40px', height: '40px' }} />
            </ButtonNext>
        </CarouselProvider>
    );
}

export default RecommendationItems;
